<template>
  <div class="list-item with-icon multiple-select-list">
    <div @click="$emit('select')">
      <div v-if="selectMultiple" class="multiselect-wrapper">
        <v-checkbox
          class="checkbox-large-icon mr-4"
          :input-value="isSelected"
        />
      </div>
      <v-list-item-avatar v-else tile>
        <v-img height="30px" contain src="@/assets/svg/timesheet_icon.svg" />
      </v-list-item-avatar>
    </div>
    <v-list-item-content @click="handleSelect(timesheet)">
      <div class="item-right timesheet-time-details" :items="groupedDates">
        <div class="description">
          <div class="d-flex" :class="{ 'flex-column': isMobile }">
            <span class="font-weight-bold">
              {{ timesheet.worker | fullName }} - {{ location }}
            </span>
          </div>
          <div class="status-wrapper">
            <div class="application-status-item">
              <div class="mx-1">
                <div class="discrepancy-field">
                  {{ totalWeekTime }}
                  <div
                    v-if="
                      showTotalTimeDiscrepancy &&
                        isUnitTime &&
                        timesheet.status != 'draft'
                    "
                    class=" discrepancy"
                    :class="{ 'text-green': totalTimeDiscrepancy < 0 }"
                  >
                    {{ formattedTotalExpectedTime }}
                  </div>
                </div>
              </div>
              <span v-if="totalCost">
                | {{ totalCost | cost | currency(currencyCode) }}
              </span>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column align-center">
          <span class="booking-list-item__mono mb-1">T-{{ timesheet.id }}</span>
          <Chip
            :color="getColorByStatus(timesheet.status)"
            class="chip--status"
            shrink
          >
            {{ timesheet.status | upperCase }}
          </Chip>
        </div>
      </div>
    </v-list-item-content>
  </div>
</template>

<script>
import { getColorByStatus } from "@/utils/timesheets";
import Chip from "@/components/common/Chip";
import { get, sortBy, includes } from "lodash";
import pluralize from "pluralize";
import { groupByDate } from "@/utils/helpers";
import { DATE_FORMAT_WITH_DAY } from "@/constants/common";
import moment from "moment";
import { isPermissioned } from "@/utils/permissions";
import { isWorker } from "@/utils/users";
import { AUTH_NAMESPACE } from "@/store/modules/auth";
import { createNamespacedHelpers } from "vuex";
import { totalDuration, getMinutesAsHrsAndMinsTime } from "@/utils/time";

const ENTRY_TIME_FORMAT = "HH:mm";
const EMPTY_TIME = "00:00:00";
const DAY_SUFFIX = "day";

export const EDITABLE_STATUSES = ["draft", "disputed"];
const { mapState } = createNamespacedHelpers(AUTH_NAMESPACE);

export default {
  props: {
    timesheet: {
      type: Object,
      required: true
    },
    selectMultiple: Boolean,
    isSelected: Boolean
  },
  data() {
    return {
      totalExpectedCost: 0,
      totalExpectedWeekTime: 0,
      totalWorked: 0,
      totalCost: 0,
      currencyCode: this.timesheet.currency_code
    };
  },
  components: {
    Chip
  },
  computed: {
    ...mapState({
      user: state => state.userData
    }),
    times() {
      return this.timesheet.entries;
    },
    isUnitTime() {
      return this.timesheet.timeUnitName === "hour";
    },
    formattedTotalExpectedTime() {
      const hourDiscrepancy = this.totalWorked - this.totalExpectedWeekTime;
      const prefix = hourDiscrepancy > 0 ? "+" : "";
      return `(${prefix}${getMinutesAsHrsAndMinsTime(hourDiscrepancy)})`;
    },
    totalWeekTime() {
      if (this.isUnitTime) {
        return getMinutesAsHrsAndMinsTime(this.totalWorked);
      }
      return this.getTotalDaysWorked();
    },
    totalTimeDiscrepancy() {
      return this.totalWorked - this.totalExpectedWeekTime;
    },
    showTotalTimeDiscrepancy() {
      return this.totalTimeDiscrepancy !== 0;
    },
    rootOrgUnitName() {
      const root = get(this.timesheet, "rootClient");
      return root && root.name;
    },
    clientName() {
      const client = get(this.timesheet, "client");
      return client && client.name;
    },
    groupedDates() {
      return this.getGroupedDates();
    },
    booking() {
      return this.timesheet.booking || {};
    },
    location() {
      if (!this.booking) {
        return "No Booking";
      }

      if (!this.booking.location || !this.booking.location.line2) {
        return "No Address";
      }

      return this.booking.location.line2;
    },
    entries() {
      return this.timesheet.entries;
    }
  },
  methods: {
    getColorByStatus,
    timeDiscrepancy({ expectedTotalTime, totalTime }) {
      return totalTime - expectedTotalTime;
    },
    getTotalDaysWorked() {
      return totalDuration(this.totalWorked, "day");
    },
    getDaysWorked(days) {
      if (days <= 0) {
        return "-";
      }
      return `${days} ${pluralize(DAY_SUFFIX, days)}`;
    },
    getHourDiscrepancy(hourDetails) {
      const { expectedTotalTime, totalTime } = hourDetails;
      const discrepancyInMinutes = totalTime - expectedTotalTime;
      const prefix = discrepancyInMinutes > 0 ? "+" : "";
      return `(${prefix}${getMinutesAsHrsAndMinsTime(discrepancyInMinutes)})!`;
    },
    getGroupedDates() {
      this.totalCost = 0;
      this.totalExpectedCost = 0;
      this.totalExpectedWeekTime = 0;
      this.totalWorked = 0;
      const sortedTimes = sortBy(this.times, [`dayDate`]);
      const groupedDatesObj = groupByDate({
        items: sortedTimes,
        key: "dayDate",
        format: DATE_FORMAT_WITH_DAY
      });

      return Object.keys(groupedDatesObj).reduce((groupedDates, key) => {
        const times = [];
        const totalWorked = [];
        const costs = [];
        const payRates = [];
        const isAbsent = [];
        groupedDatesObj[key].forEach(
          ({
            startTime,
            endTime,
            cost,
            currency_code: currencyCode,
            expected_cost: expectedCost,
            expectedTotalTime,
            totalTime,
            breakMinutes,
            absent,
            "pay-rates": payRate
          }) => {
            if (!this.globalCurrencyCode) {
              this.globalCurrencyCode = currencyCode;
            }
            this.totalCost += cost;
            this.totalExpectedCost += expectedCost;
            const start = this.getFormattedTime(startTime);
            const end = this.getFormattedTime(endTime);
            this.totalWorked += totalTime;
            this.totalExpectedWeekTime += expectedTotalTime;
            const time = `${start}-${end}`;
            times.push(time);
            totalWorked.push({ totalTime, expectedTotalTime, breakMinutes });
            costs.push({ cost, currencyCode, expectedCost });
            payRates.push(payRate ? payRate.activityTypeName : "Standard");
            isAbsent.push(absent);
          }
        );
        return [
          ...groupedDates,
          { date: key, times, totalWorked, costs, payRates, isAbsent }
        ];
      }, []);
    },
    getFormattedTime(time) {
      return moment(time || EMPTY_TIME, "HH:mm:ss").format(ENTRY_TIME_FORMAT);
    },
    handleSelect({ id, status }) {
      const showSubmitTimesheetView =
        includes(EDITABLE_STATUSES, status) &&
        isPermissioned(`timesheets.edit`) &&
        isWorker(this.user.roles);
      this.$router.push({
        name: showSubmitTimesheetView ? "submitTimesheet" : "timesheetDetails",
        params: { id }
      });
    }
  }
};
</script>

<style lang="scss">
.text-green {
  color: green !important;
}
</style>
