import {
  map,
  flatten,
  includes,
  some,
  size,
  every,
  isEmpty,
  reduce,
  find,
  startCase,
  filter
} from "lodash";
import { getFullName } from "@/utils/users";
import moment from "moment";
import { API_DATE_FORMAT } from "@/constants/common";
import { formatDate } from "@/utils/time";
import { RRule } from "rrule";
import {
  setHoursAndMinutes,
  splitHourAndMinutes,
  convertStringDate,
  isDateCorrect
} from "@/utils/time";

const SHIFT_STATUSES = {
  UNALLOCATED: "Unallocated",
  PART_ALLOCATED: "Part-Allocated",
  ALLOCATED: "Allocated",
  CONFIRMED: "Confirmed"
};

const HOUR_MINUTE_FORMAT = "HH:mm";
const DAY_FORMAT = "Do MMMM YYYY";
const SHIFT_DAY_FORMAT = "dddd Do MMMM YYYY";

export const getShiftStatus = ({
  workersRequired,
  workersAllocated,
  workersConfirmed
}) => {
  const workersAlreadyInShift = workersAllocated + workersConfirmed;

  if (workersAlreadyInShift >= workersRequired) {
    return SHIFT_STATUSES.ALLOCATED;
  }
  if (workersAlreadyInShift === 0) {
    return SHIFT_STATUSES.UNALLOCATED;
  }
  if (workersConfirmed > 0) {
    return SHIFT_STATUSES.CONFIRMED;
  }
  return SHIFT_STATUSES.PART_ALLOCATED;
};
export const getShiftsWithPermittedApplications = (shifts, permission) => {
  if (isEmpty(shifts)) {
    return [];
  }

  return map(shifts, shift => ({
    ...shift,
    workers: shift.applicationShifts.reduce(
      (workers, { id, application, meta: { permissions } }) => {
        if (permissions[`application-shifts.${permission}`]) {
          workers.push({
            ...application.user,
            fullName: getFullName(application.user),
            applicationId: id, //shift user application id
            userApplicationId: application.id
          });
        }
        return workers;
      },
      []
    )
  }));
};

export const getShiftsWithPermittedApplicationsV2 = (shifts, permission) => {
  // console.log("getShiftsWithPermittedApplications2");
  if (!shifts || shifts.length === 0) {
    return [];
  }

  return shifts.map(shift => {
    // console.log(shift);
    const permittedWorkers = shift.applicationShifts.reduce(
      (workers, applicationShift) => {
        const applicationPermissions = applicationShift.permissions || [];
        const applicationPermission = find(
          applicationPermissions,
          ({ permissionName }) =>
            permissionName === `application-shifts.${permission}`
        );
        if (applicationPermission.hasPermission) {
          workers.push({
            ...applicationShift.application.user,
            fullName: getFullName(applicationShift.application.user),
            applicationId: applicationShift.id, // shift user application id
            userApplicationId: applicationShift.application.id
          });
        }

        return workers;
      },
      []
    );

    return {
      ...shift,
      workers: permittedWorkers
    };
  });
};

export const getPermittedApplicationShiftsIds = ({ shifts, permission }) => {
  if (isEmpty(shifts)) {
    return [];
  }

  return flatten(
    map(shifts, shift =>
      shift.applicationShifts.reduce(
        (applicationShiftsIds, { id, meta: { permissions } }) => {
          if (permissions[`application-shifts.${permission}`]) {
            applicationShiftsIds.push(id);
          }
          return applicationShiftsIds;
        },
        []
      )
    )
  );
};

export const getPermittedApplicationShiftsIdsV2 = ({ shifts, permission }) => {
  if (isEmpty(shifts)) {
    return [];
  }

  return flatten(
    map(shifts, shift =>
      shift.applicationShifts.reduce(
        (applicationShiftsIds, { id, permissions }) => {
          const selectedPermission = find(
            permissions,
            ({ permissionName }) =>
              permissionName === `application-shifts.${permission}`
          );
          if (selectedPermission.hasPermission) {
            applicationShiftsIds.push(id);
          }
          return applicationShiftsIds;
        },
        []
      )
    )
  );
};

export const getFilteredShiftStatuses = statuses => {
  return Object.keys(statuses).reduce((filteredStatuses, key) => {
    if (statuses[key]) {
      filteredStatuses[key] = statuses[key];
    }

    return filteredStatuses;
  }, {});
};

const doesArrayItemIncludesGivenValue = (arr, val) => {
  return some(arr, item => includes(item, val));
};

export const getDateTimeFiltersCount = dateTimeFilters => {
  let count = 0;
  const filterKeys = Object.keys(dateTimeFilters);
  if (doesArrayItemIncludesGivenValue(filterKeys, "Date")) {
    count++;
  }
  if (doesArrayItemIncludesGivenValue(filterKeys, "Time")) {
    count++;
  }
  return count;
};

export const checkIfSelectedApplicationShiftsArePermitted = (
  { shifts, permission, nestedApplicationShifts },
  model = "application-shifts"
) => {
  if (!size(shifts)) {
    return false;
  }
  return every(shifts, shift => {
    const applicationShifts = nestedApplicationShifts
      ? shift.applicationShifts
      : [shift];
    return some(applicationShifts, ({ meta: { permissions } }) => {
      return permissions[`${model}.${permission}`];
    });
  });
};

export const checkIfSelectedShiftsArePermitted = (
  { shifts, permission },
  model = "shifts"
) => {
  if (!size(shifts)) {
    return false;
  }
  return every(shifts, shift => {
    const { permissions } = shift;
    const selectedPermission = find(
      permissions,
      ({ permissionName }) => permissionName === `${model}.${permission}`
    );
    return selectedPermission && selectedPermission.hasPermission;
  });
};

export const checkIfSelectedApplicationsArePermitted = (
  { shifts, permission },
  model = "application-shifts"
) => {
  if (!size(shifts)) {
    return false;
  }

  return shifts.some(shift => {
    const { applicationShifts } = shift;
    return applicationShifts.some(({ permissions }) => {
      const selectedPermission = permissions.find(
        perm => perm.permissionName === `${model}.${permission}`
      );
      return selectedPermission && selectedPermission.hasPermission;
    });
  });
};

export const groupByShiftStatus = shifts => {
  return reduce(
    shifts,
    (grouped, application) => {
      const propertyName = "status";
      let status = application[propertyName];
      if (status === "submitted") {
        status = "submitted for";
      }
      const groupedByProperty = grouped[status];
      if (!groupedByProperty) {
        grouped[status] = [application];
      } else {
        grouped[status] = [...groupedByProperty, application];
      }
      return grouped;
    },
    {}
  );
};

export const getAllShiftUsers = shifts => {
  return reduce(
    shifts,
    (applicationUsers, shift) => {
      map(shift.applicationShifts, applicationShift => {
        const { application } = applicationShift;
        if (application && application.user) {
          const { user } = application;
          if (!find(applicationUsers, user)) {
            applicationUsers.push(user);
          }
        }
      });
      return applicationUsers;
    },
    []
  );
};

export const getShiftStatusFilters = statuses => {
  return map(statuses, status => {
    return { text: startCase(status), value: status };
  });
};

export const getShiftWorkerFilters = workers => {
  return map(workers, worker => {
    return { text: getFullName(worker), value: worker.id };
  });
};

const isRejected = status => {
  return status === "declined" || status === "cancelled";
};

export const getFilteredShifts = (shifts, filters) => {
  let filteredShifts = shifts;
  if (filters.startDate && !filters.endDate) {
    filteredShifts = filter(filteredShifts, shift =>
      moment(shift.startDate).isSameOrAfter(moment(filters.startDate))
    );
  }
  if (filters.startDate && filters.endDate) {
    filteredShifts = filter(
      filteredShifts,
      shift =>
        moment(shift.startDate).isSameOrAfter(moment(filters.startDate)) &&
        moment(shift.startDate).isSameOrBefore(moment(filters.endDate))
    );
  }
  if (filters.status) {
    filteredShifts = reduce(
      filteredShifts,
      (filteredStatus, shift) => {
        map(filters.status, status => {
          if (status === shift.status && !find(filteredStatus, shift)) {
            filteredStatus.push(shift);
          }
        });
        return filteredStatus;
      },
      []
    );
  }
  if (filters.worker) {
    filteredShifts = reduce(
      filteredShifts,
      (filteredUser, shift) => {
        map(shift.applicationShifts, applicationShift => {
          const { application } = applicationShift;
          if (
            application &&
            application.user &&
            !isRejected(applicationShift.status)
          ) {
            const { user } = application;
            map(filters.worker, id => {
              if (id === user.id && !find(filteredUser, shift)) {
                filteredUser.push(shift);
              }
            });
          }
        });
        return filteredUser;
      },
      []
    );
  }
  return filteredShifts;
};

export const shiftHourRange = (startDate, endDate) => {
  const startHour = moment(startDate, API_DATE_FORMAT).format(
    HOUR_MINUTE_FORMAT
  );
  const endHour = moment(endDate, API_DATE_FORMAT).format(HOUR_MINUTE_FORMAT);
  return `${startHour}-${endHour}`;
};

export const shiftDayFormat = (date, endFormat = DAY_FORMAT) =>
  formatDate({
    date,
    endFormat
  });

export const getColorByPercentage = filled => {
  if (filled >= 100) {
    return "#17D99B";
  } else if (filled > 79 && filled < 100) {
    return "#FFB800";
  } else {
    return "#EA0B40";
  }
};

export const getConfirmedWorkersCount = (applicationShifts, agencyId) => {
  return reduce(
    applicationShifts,
    (count, applicationShift) => {
      const { application, status } = applicationShift;
      if (application) {
        const { user } = application;
        if (user) {
          const { organisation } = user;
          if (organisation) {
            if (organisation.id === agencyId && status === "confirmed") {
              count++;
            }
          }
        }
      }
      return count;
    },
    0
  );
};

export const getShiftQuota = (quotas, scheduleId) => {
  return find(
    quotas,
    ({ publishingScheduleId }) =>
      Number(publishingScheduleId) === Number(scheduleId)
  );
};

export const getSchedulesWithConfirmedWorkersCount = ({
  schedules,
  applicationShifts,
  shiftQuotas,
  shiftId
}) => {
  return map(schedules, schedule => {
    const shiftQuota = getShiftQuota(shiftQuotas, schedule.id);
    return {
      ...schedule,
      confirmed: getConfirmedWorkersCount(
        applicationShifts,
        schedule.agency.id
      ),
      workerQuota: shiftQuota
        ? shiftQuota.workerQuota
        : schedule.quotas &&
          (schedule.quotas.shiftId === shiftId || !schedule.quotas.shiftId)
        ? schedule.quotas.workerQuota
        : 0
    };
  });
};

export const getConfirmedWorkers = applicationShifts => {
  return reduce(
    applicationShifts,
    (workers, applicationShift) => {
      const { application, status } = applicationShift;
      if (application) {
        const { user } = application;
        if (user) {
          if (status === "confirmed" && !find(workers, user)) {
            workers.push(user);
          }
        }
      }
      return workers;
    },
    []
  );
};

export const getShiftDateRange = shiftRRule => {
  const start = shiftDayFormat(shiftRRule.dtstart);
  const end = shiftDayFormat(shiftRRule.until);
  return `${start} - ${end}`;
};

export const getShiftSchedulesQuotas = ({ schedules, shiftQuotas }) => {
  return map(schedules, schedule => {
    const shiftQuota = getShiftQuota(shiftQuotas, schedule.id);
    return {
      ...schedule,
      workerQuota: shiftQuota
        ? shiftQuota.workerQuota
        : schedule && schedule.quotas
        ? schedule.quotas.workerQuota
        : 0,
      isShiftQuota: Boolean(shiftQuota),
      quotaId: shiftQuota && shiftQuota.id
    };
  });
};

export const groupShiftByTime = shifts => {
  return reduce(
    shifts,
    (groupedShifts, shift) => {
      const key = `${shiftHourRange(
        shift.startDate,
        shift.endDate
      )}, ${shiftDayFormat(shift.startDate, SHIFT_DAY_FORMAT)}`;
      if (groupedShifts[key]) {
        groupedShifts[key].value.push(shift);
        return groupedShifts;
      }
      const newGroup = {
        value: [shift],
        label: key
      };
      return { ...groupedShifts, [key]: newGroup };
    },
    {}
  );
};

export const normalizedShift = shift => {
  let shiftStart = shift.recurrence.dtstart;
  let shiftEnd = shift.recurrence.dtstart;
  let shiftUntil = shift.recurrence.until;

  if (!isDateCorrect(shiftStart)) {
    return shift;
  }

  if (shift.fromHour && shift.toHour) {
    const [startHour, startMinute] = splitHourAndMinutes(shift.fromHour);
    const [endHour, endMinute] = splitHourAndMinutes(shift.toHour);

    shiftUntil = setHoursAndMinutes(shiftUntil, {
      hours: endHour,
      minutes: endMinute
    });
    shiftStart = setHoursAndMinutes(shiftStart, {
      hours: startHour,
      minutes: startMinute
    });
    shiftEnd = setHoursAndMinutes(shiftEnd, {
      hours: endHour,
      minutes: endMinute
    });

    shift.recurrence.dtstart = convertStringDate(shiftStart);
    shift.recurrence.until = convertStringDate(shiftUntil);
  }
  return {
    rrule: new RRule(shift.recurrence).toString(),
    workersRequired: shift.workersRequired,
    activity: shift.activity,
    shiftStart,
    shiftEnd,
    status: shift.status,
    id: shift.id
  };
};

export const generateDefaultShiftForFixedTerm = ({
  startDate,
  endDate,
  activity
}) => {
  const newShift = {
    recurrence: {
      freq: RRule.WEEKLY,
      dtstart: startDate,
      until: endDate,
      byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR]
    },
    fromHour: process.env.DEFAULT_SHIFT_START_TIME ?? "09:00",
    toHour: process.env.DEFAULT_SHIFT_END_TIME ?? "17:00",
    workersRequired: process.env.DEFAULT_WORKERS_REQUIRED ?? 1,
    activity,
    status: "approved"
  };

  return normalizedShift(newShift);
};
