<template>
  <BaseDialog :isOpen="isOpen" @updateDialog="cancel">
    <template v-slot:title>
      <span>
        Are you sure you want to confirm the allocation of this worker?
      </span>
    </template>
    <template v-slot:body>
      <div class="confirm-allocations-description">
        Confirmed allocations will have timesheets generated.
      </div>
    </template>
    <template v-slot:actions>
      <div class="confirm-allocations-actions">
        <PrimaryButton @click.native="cancel" text>
          Cancel
        </PrimaryButton>
        <PrimaryButton
          :loading="isLoading"
          @click.native="handleConfirmAllocation"
        >
          Confirm Allocation
        </PrimaryButton>
      </div>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/components/common/BaseDialog";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { createNamespacedHelpers } from "vuex";
import { APPLICATION_NAMESPACE } from "@/store/modules/applications";
import {
  CONFIRM_ALLOCATION,
  UPDATE_APPLICATION_SHIFTS_STATUS
} from "@/store/modules/applications/actions";
import { SHIFT_STATUSES } from "@/constants/shifts";
import { BOOKINGS_NAMESPACE } from "@/store/modules/bookings/actions";

const { mapActions } = createNamespacedHelpers(APPLICATION_NAMESPACE);
const { mapState } = createNamespacedHelpers(BOOKINGS_NAMESPACE);

export default {
  name: "ConfirmCandidateAllocationDialog",
  components: { BaseDialog, PrimaryButton },
  props: {
    isOpen: Boolean,
    applicationIds: Array
  },
  inject: ["currentBookingId"],
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      currentBooking: state => state.currentBooking
    })
  },
  methods: {
    ...mapActions({
      confirmAllocation: CONFIRM_ALLOCATION,
      updateApplicationShiftsStatus: UPDATE_APPLICATION_SHIFTS_STATUS
    }),
    async handleConfirmAllocation() {
      this.isLoading = true;
      try {
        await this.updateApplicationShiftsStatus({
          applicationShifts: this.applicationIds,
          status: SHIFT_STATUSES.CONFIRMED,
          all: true,
          booking: this.currentBooking
        });
        this.$emit("confirmed");
      } finally {
        this.isLoading = false;
      }
    },
    cancel() {
      this.$emit("close", { updateList: false });
    }
  }
};
</script>

<style lang="scss">
.confirm-allocations-description {
  min-height: 100px;
}
.confirm-allocations-actions {
  padding: 0.5rem;
}
</style>
