<template>
  <BaseDialog :isOpen="isOpen" @updateDialog="cancel">
    <template v-slot:title>
      <span>
        Are you sure you want to allocate this worker?
      </span>
    </template>
    <template v-slot:body>
      <div class="confirm-allocations-description">
        <br>The agency will be asked to confirm their availability.
      </div>
    </template>
    <template v-slot:actions>
      <div class="confirm-allocations-actions">
        <PrimaryButton @click.native="cancel" text>
          Cancel
        </PrimaryButton>
        <PrimaryButton
          :loading="isLoading"
          @click.native="handleConfirmAllocation"
        >
          Allocate
        </PrimaryButton>
      </div>
    </template>
  </BaseDialog>
</template>

<script>
import { map } from "lodash";
import BaseDialog from "@/components/common/BaseDialog";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { createNamespacedHelpers } from "vuex";
import { APPLICATION_NAMESPACE } from "@/store/modules/applications";
import { ALLOCATE_CANDIDATES } from "@/store/modules/applications/actions";

const { mapActions } = createNamespacedHelpers(APPLICATION_NAMESPACE);

export default {
  name: "AllocateCandidateDialog",
  components: { BaseDialog, PrimaryButton },
  props: {
    isOpen: Boolean,
    shifts: Array,
    workerIds: Array
  },
  inject: ["currentBookingId"],
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    ...mapActions({
      allocateCandidates: ALLOCATE_CANDIDATES
    }),
    async handleConfirmAllocation() {
      this.isLoading = true;
      try {
        const allocationRequests = map(this.shifts, (shift, index) => {
          return new Promise(resolve => setTimeout(resolve, index * 300)).then(
            () =>
              this.allocateCandidates({
                bookingId: this.currentBookingId,
                shiftId: shift.id,
                users: this.workerIds
              })
          );
        });
        await Promise.all(allocationRequests);
        this.$emit("allocated");
      } finally {
        this.isLoading = false;
      }
    },
    cancel() {
      this.$emit("close", { updateList: false });
    }
  }
};
</script>

<style lang="scss">
.confirm-allocations-description {
  min-height: 100px;
}
.confirm-allocations-actions {
  padding: 0.5rem;
}
</style>
