<template>
  <BaseDetails class="compliance-check-section">
    <template v-slot:action />
    <template v-slot:header v-if="showHeader">
      <div class="compliance-check-header">
        <label class="section-label mb-3">
          <h3>
            Compliance Checks
            <span class="compliance-check-header__subtitle">
              ({{ verifiedChecks }} of {{ numberOfChecks }} complete)
            </span>
          </h3>
        </label>
      </div>
    </template>
    <template v-slot:body>
      <div class="d-flex flex-column" v-if="!expanded">
        <h4 v-if="completedCompliances.length">Complete</h4>
        <ComplianceChip
          v-if="completedCompliances.length"
          :compliances="completedCompliances"
          complete
        />
        <h4 v-if="incompleteCompliances.length">Incomplete</h4>
        <ComplianceChip
          v-if="incompleteCompliances.length"
          :compliances="incompleteCompliances"
        />
      </div>
      <v-expansion-panels accordion multiple flat hover v-else>
        <v-expansion-panel v-for="compliance in checks" :key="compliance.id">
          <v-expansion-panel-header expand-icon="" disabled class="mb-3">
            <ComplianceCheck
              :compliance="compliance"
              :references="references"
            />
          </v-expansion-panel-header>
        </v-expansion-panel>
      </v-expansion-panels>
      <PrimaryButton
        @click.native="expanded = !expanded"
        class="p-0"
        shrink
        text
      >
        {{ buttonLabel }}
        <v-icon class="ml-1" size="25px">
          {{ `mdi-chevron-${expanded ? "up" : "down"}` }}
        </v-icon>
      </PrimaryButton>
    </template>
  </BaseDetails>
</template>

<script>
import BaseDetails from "@/components/common/BaseDetails/BaseDetails";
import ComplianceCheck from "@/components/workers/common/ComplianceCheck";
import { COMPLIANCE_CHECKS_WITH_DETAILS } from "@/constants/workers";
import { includes, isEmpty } from "lodash";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import ComplianceChip from "@/components/workers/common/ComplianceChip";

export default {
  name: "ComplianceCheckList",
  components: {
    ComplianceCheck,
    BaseDetails,
    PrimaryButton,
    ComplianceChip
  },
  props: {
    checks: Array,
    references: Array,
    showHeader: Boolean
  },
  data() {
    return {
      expanded: false
    };
  },
  computed: {
    verifiedChecks() {
      return this.checks.filter(check => check.checkVerified).length;
    },
    completedCompliances() {
      return this.checks.filter(check => check.checkVerified);
    },
    incompleteCompliances() {
      return this.checks.filter(check => !check.checkVerified);
    },
    numberOfChecks() {
      return this.checks.length;
    },
    buttonLabel() {
      return this.expanded ? `Less` : `More`;
    }
  },
  methods: {
    showDetails(compliance) {
      return (
        (includes(
          COMPLIANCE_CHECKS_WITH_DETAILS,
          compliance.complianceCheckName
        ) &&
          compliance.checkVerified) ||
        !isEmpty(compliance.files)
      );
    }
  }
};
</script>

<style lang="scss"></style>
