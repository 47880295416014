var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"candidates-wrapper"},[_c('div',[_c('div',{staticClass:"container col-lg-12 col-sm-12 candidates"},[(_vm.isFetchingData)?_c('div',_vm._l((15),function(i){return _c('v-skeleton-loader',{key:i,attrs:{"type":"list-item-avatar"}})}),1):(_vm.isNotEmpty)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 candidate-list",class:{ 'col-md-4': _vm.showDetails, 'with-details': _vm.showDetails }},[_c('div',{staticClass:"candidates-toolbar"},[_c('DynamicSearchBar',{on:{"search":_vm.onSearch}})],1),_vm._l((_vm.applicationsToDisplay),function(applications,groupsKey,index){return _c('div',{key:index,class:{ 'mt-4': index > 0 }},[(index > 0)?_c('v-divider',{staticClass:"mb-2"}):_vm._e(),_c('div',{staticClass:"status"},[_c('CandidateStatusHeader',{attrs:{"status":groupsKey,"size":applications.length}})],1),_vm._l((applications),function(application,index){return _c('div',{key:index,staticClass:"worker-wrapper multiselect-wrapper",class:{
                'worker-selected': _vm.isSelected(application)
              },on:{"click":function($event){return _vm.selectApplication(application)}}},[(_vm.selectMultiple)?_c('v-checkbox',{staticClass:"checkbox-large-icon",attrs:{"size":"2rem","input-value":_vm.isSelected(application)}}):_c('div',{staticClass:"icon-wrapper",on:{"click":_vm.triggerCheckboxMode}},[_c('v-icon',[_vm._v("mdi-account-outline")])],1),_c('div',[_vm._v(" "+_vm._s(_vm.candidate(application))+" ")])],1)})],2)})],2),(_vm.showDetails && !_vm.isMobile)?_c('CandidateDetailsDesktop',{key:_vm.detailsKey,staticClass:"col-12 col-md-8",attrs:{"application":_vm.first(_vm.selectedApplications)}}):(_vm.showDetails)?_c('CandidateDetailsMobile',{key:_vm.detailsKey,attrs:{"application":_vm.first(_vm.selectedApplications)},on:{"close":function($event){_vm.selectedApplications = []}}}):_vm._e()],1):_c('EmptyStates',{attrs:{"icon":"applications-empty-state","description":"No applications have been made yet"}})],1),_vm._t("action")],2),_c('BaseFooter',{staticClass:"relative"},[_c('MultipleSelectAction',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectMultiple),expression:"selectMultiple"}],attrs:{"selectedCount":_vm.selectedCount,"isSelectAll":_vm.isSelectAll,"items":_vm.categories},on:{"selectAll":_vm.handleSelectAll,"cancel":_vm.resetSelection}})],1),(_vm.selectedApplications.length && !_vm.isFetchingData)?_c('BaseFooter',[_c('ExpandedButtons',[_c('div',{staticClass:"permissioned-container actions-bar",class:{ vertical: _vm.isMobile }},[(_vm.checkIfSelectedApplicationsArePermitted('withdraw'))?_c('WithdrawCandidateAction',{attrs:{"applicationIds":_vm.selectedApplicationsIds},on:{"statusChanged":_vm.refreshView}}):_vm._e(),(
            _vm.checkIfSelectedApplicationsArePermitted('reject') &&
              !_vm.isSomeApplicationAllocated
          )?_c('RejectCandidateAction',{attrs:{"applications":_vm.selectedApplications},on:{"statusChanged":_vm.refreshView}}):_vm._e(),(_vm.checkIfSelectedApplicationsArePermitted('add-assessment'))?_c('AddAssessmentAction',{attrs:{"applications":_vm.selectedApplications}}):_vm._e(),(_vm.checkIfSelectedApplicationsArePermitted('request-interview'))?_c('RequestInterviewAction',{attrs:{"applicationsIds":_vm.selectedApplicationsIds}}):_vm._e(),(_vm.checkIfSelectedApplicationsArePermitted('approve'))?_c('ApproveCandidateAction',{attrs:{"applicationsIds":_vm.selectedApplicationsIds},on:{"statusChanged":_vm.refreshView}}):_vm._e(),(_vm.checkIfSelectedApplicationsArePermitted('shortlist'))?_c('ShortlistCandidateAction',{attrs:{"applicationsIds":_vm.selectedApplicationsIds},on:{"statusChanged":_vm.refreshView}}):_vm._e(),(
            _vm.checkIfSelectedApplicationsArePermitted('allocate') &&
              _vm.checkAllShiftsCanBeAllocated()
          )?_c('AllocateCandidateAction',{attrs:{"applications":_vm.selectedApplications,"shifts":_vm.bookingShifts},on:{"allocated":_vm.refreshView}}):_vm._e(),(_vm.checkCanConfirmAllocation() && _vm.isEveryApplicationAllocated)?_c('ConfirmCandidateAllocationAction',{attrs:{"applicationIds":_vm.selectedApplicationShiftApplicationIds},on:{"confirmed":_vm.refreshView}}):_vm._e()],1)])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }